import { Component, OnInit } from '@angular/core';
import { participantLabel, captainLabel } from '@utils/constants';


@Component({
  selector: 'oamw-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqPage implements OnInit {
  participantLabel: string;
  captainLabel: string;

  constructor() {
    this.participantLabel = participantLabel;
    this.captainLabel = captainLabel;
   }

  ngOnInit(): void {
    if(navigator.platform.match('Mac') !== null) {
      document.getElementById('faq').classList.add('mac-faq');
    }

  }

}
