<div class="grad1">
  <section id="login" class="mt-auto">
    <div class="container">
      <div class="login-box mobile-hide">
        <form (submit)="doLogin()" [formGroup]="form">
            <div class="row">
              <div class="col text-center">
                <img src="assets/images/voce/voce_terracotta.png" class="logo-login pt-4 pb-5">
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="input-group input-group-lg mt-3" [ngClass]="{ 'input-group-error': isFieldInvalid('email') }">
                  <input type="email" class="form-control" name="email" formControlName="email" placeholder="USERNAME"
                    aria-label="Username" aria-describedby="basic-addon1" tabindex="1">
                </div>
                <div class="validation-error-message" *ngIf="isFieldInvalid('email')">
                  <span>Please enter a valid email address</span>
                </div>

                <div class="input-group input-group-lg mt-3"
                  [ngClass]="{ 'input-group-error': isFieldInvalid('password') }">
                  <input type="password" class="form-control" name="password" formControlName="password"
                    placeholder="PASSWORD" aria-label="Username" aria-describedby="basic-addon1" tabindex="2">
                </div>
                <div class="validation-error-message" *ngIf="isFieldInvalid('password')">
                  <span>Please enter your password</span>
                </div>

              </div>
            </div>

          <div class="row">
            <div class="col text-center">
              <div class="validation-error-message pt-2" *ngIf="showError">
                <span>Invalid Credentials</span>
              </div>
              <button type="submit" class="btn btn-primary btn-desktop bg-purple mt-4" tabindex="3">SIGN IN</button>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <a [routerLink]="['/forgot-password']" class="btn btn-text btn-desktop text-purple mt-2 mb-4" tabindex="4"><u>Forgot Password</u></a>
            </div>
          </div>
        </form>
      </div>
      <div class="login-box-mobile desktop-hide">
        <form (submit)="doLogin()" [formGroup]="form">
          <div class="row">
            <div class="col text-center">
              <a href="/"><img src="assets/images/voce/VoceLogo-White.png" class="logo-login pt-4 pb-5"></a>
            </div>
          </div>
          <div class="row input-box">
            <div class="col">
              <div class="input-group input-group-lg mt-3" [ngClass]="{ 'input-group-error': isFieldInvalid('email') }">
                <input type="email" class="form-control" name="email" formControlName="email" placeholder="USERNAME"
                       aria-label="Username" aria-describedby="basic-addon1">
              </div>
              <div class="validation-error-message" *ngIf="isFieldInvalid('email')">
                <span>Please enter a valid email address</span>
              </div>

              <div class="input-group input-group-lg mt-3"
                   [ngClass]="{ 'input-group-error': isFieldInvalid('password') }">
                <input type="password" class="form-control" name="password" formControlName="password"
                       placeholder="PASSWORD" aria-label="Username" aria-describedby="basic-addon1">
              </div>
              <div class="validation-error-message" *ngIf="isFieldInvalid('password')">
                <span>Please enter your password</span>
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <div class="validation-error-message pt-3 pl-5" *ngIf="showError">
                <span>Invalid Credentials</span>
              </div>
              <button type="submit" class="btn btn-primary btn-desktop bg-purple mt-4" tabindex="3">SIGN IN</button>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <a [routerLink]="['/forgot-password']" class="btn btn-text btn-desktop text-purple mt-2 mb-4"><u>Forgot Password</u></a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>
