<div class="column" [ngClass]="isLoggedIn ? '' : 'right-signup'">
    <section id="topicSelect" class="bg-grey topicSelect">
      <span (click)="goBack()" class="pointer left-arrow mobile-hide">
          <img src="assets/images/angle-left-orange.svg" class="back-arrow">
      </span>
      <div>
        <div class="text-center" *ngIf="!isLoggedIn">
            <h1 class="font-color-teracotta">Select a Topic</h1>
        </div>
        <div class="col text-center desktop-hide">
          <img src="assets/images/process-step-two.png" class="img-fluid step-image-padding">
        </div>
        <div class="col text-center mobile-hide">
          <img src="assets/images/progress_step_two.svg" class="img-progress">
        </div>
        <div class="text-center loggedIn-header" *ngIf="isLoggedIn">
          <h1 class="">Select a Topic</h1>
          <h2 class="description mt-3">
            Click the topic for which you would like to speak to a Patient {{captainLabel}}
          </h2>
        </div>
        <div *ngIf="!isLoggedIn">
          <div class="select-list">
            <ul class="list-group list-topic">
              <li class="list-group-item topic-item bg-purple text-center cursor-p" [ngClass]="selectedTopicID === t.id ? 'selected' : ''"
                  (click)="selectTopic(t.id)" *ngFor="let t of topics">
                {{t.name}}
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col text-center">
              <button type="button" class="mt-4 btn btn-primary" [disabled]="selectedTopicID === undefined" (click)="gotoFaculty()">Next</button>
            </div>
          </div>
        </div>
        <div *ngIf="isLoggedIn">
          <div class="select-list">
            <ul class="list-group list-topic">
              <li class="list-group-item topic-item bg-purple text-center cursor-p" [ngClass]="selectedTopicID === t.id ? 'selected' : ''" (click)="gotoFacultyLoggedIn(t.id)" *ngFor="let t of topics">
                {{t.name}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
</div>
