import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { environment } from 'src/environments/environment';
import { TelemetryData, TelemetryPing } from '@models/telemetry-data';


@Injectable({
  providedIn: 'root'
})
export class LiveSessionService {
  apiUrl = environment.apiUrl;
  private DATA_URL = this.apiUrl.replace(/^http/i, 'ws') + '/ws-session';

  private subject$: WebSocketSubject<TelemetryData | TelemetryPing>;

  connect() {
    this.subject$ = webSocket(this.DATA_URL);
    return this.subject$.asObservable();
  }

  disconnect() {
    this.subject$.unsubscribe();
    this.subject$ = undefined;
  }

  send(reqData: TelemetryData) {
    reqData !== undefined && this.subject$ !== undefined && this.subject$.next(reqData);
  }

  sendPing(reqData: TelemetryPing) {
    reqData !== undefined && this.subject$ !== undefined && this.subject$.next(reqData);
  }

}
