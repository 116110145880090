<div class="column" [ngClass]="isLoggedIn ? '' : 'right-signup'">
  <section id="facultySelect" class="bg-grey">
    <span (click)="goBack()" class="pointer left-arrow">
          <img src="assets/images/angle-left-orange.svg" class="back-arrow">
      </span>
    <div>
      <div class="text-center" *ngIf="!isLoggedIn">
        <h1 class="font-color-teracotta">Select an {{captainLabel}}</h1>
      </div>
      <div class="col text-center desktop-hide">
        <img src="assets/images/process-step-three.png" class="img-fluid step-image-padding">
      </div>
      <div class="col text-center mobile-hide">
        <img src="assets/images/progress_step_three.svg" class="img-progress">
      </div>
      <div class="text-center loggedIn-header" *ngIf="isLoggedIn">
        <h1 class="">Select an {{captainLabel}}</h1>
        <h2 class="description mt-3 mb-4">
          Click on the tiles below to view each {{captainLabel}} profile prior to making your selection.
        </h2>
      </div>
      <div class="card-container">
        <ng-container *ngFor="let doc of captainList; let idx = index; let od = odd; let ev = even">
          <div class="faculty-card expanded" [class.d-none]="selectedIdx != idx">
            <div class="faculty-details">
              <div class="faculty-image">
                <img [src]="'assets/images/profiles/' + doc.profileImageUrl" alt="{{captainLabel}} profile picture">
              </div>
              <div class="faculty-name">
                <h5 class="card-title">{{ doc.firstName }} {{ doc.lastName }}</h5>
                <p class="card-title">{{ doc.title }}</p>
                <div class="faculty-select-btn desktop-hide">
                  <button class="btn btn-primary select-faculty" (click)="gotoSession()">Select {{captainLabel}}</button>
                </div>
              </div>
            </div>
            <div class="faculty-details">
              <div class="faculty-bio scroll-vertical">{{ doc.biography }}</div>
              <div class="faculty-select-btn mobile-hide">
                <button class="btn btn-primary select-faculty" (click)="gotoSession()">Select {{captainLabel}}</button>
              </div>
            </div>
            <div class="faculty-close" (click)="selectedCaptain = undefined; selectedIdx = undefined">
              <svg width="12px" height="14px" viewBox="0 0 10 11" version="1.1" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: Sketch 60.1 (88133) - https://sketch.com -->
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Faculty-Selection" transform="translate(-359.000000, -482.000000)" fill="#DFE0E1"
                     fill-rule="nonzero">
                    <path
                      d="M367.148418,492.562501 C367.385397,492.562501 367.595031,492.48047 367.777323,492.316408 L367.777323,492.316408 L368.378884,491.714847 C368.542946,491.532555 368.624977,491.322921 368.624977,491.085942 C368.624977,490.848963 368.542946,490.648443 368.378884,490.484381 L368.378884,490.484381 L365.644515,487.750013 L368.378884,485.015644 C368.542946,484.851582 368.624977,484.651062 368.624977,484.414083 C368.624977,484.177104 368.542946,483.96747 368.378884,483.785178 L368.378884,483.785178 L367.777323,483.183617 C367.595031,483.019555 367.385397,482.937524 367.148418,482.937524 C366.911439,482.937524 366.710919,483.019555 366.546857,483.183617 L366.546857,483.183617 L363.812489,485.917986 L361.07812,483.183617 C360.914058,483.019555 360.713538,482.937524 360.476559,482.937524 C360.23958,482.937524 360.029946,483.019555 359.847654,483.183617 L359.847654,483.183617 L359.246093,483.785178 C359.082031,483.96747 359,484.177104 359,484.414083 C359,484.651062 359.082031,484.851582 359.246093,485.015644 L359.246093,485.015644 L361.980462,487.750013 L359.246093,490.484381 C359.082031,490.648443 359,490.848963 359,491.085942 C359,491.322921 359.082031,491.532555 359.246093,491.714847 L359.246093,491.714847 L359.847654,492.316408 C360.029946,492.48047 360.23958,492.562501 360.476559,492.562501 C360.713538,492.562501 360.914058,492.48047 361.07812,492.316408 L361.07812,492.316408 L363.812489,489.582039 L366.546857,492.316408 C366.710919,492.48047 366.911439,492.562501 367.148418,492.562501 Z"
                      id="t"></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let doc of captainList; let idx = index; let od = odd; let ev = even">
          <div class="faculty-card" [class.d-none]="selectedCaptain === doc.id">
            <div class="faculty-brief">
              <img [src]="'assets/images/profiles/' + doc.profileImageUrl" alt="{{captainLabel}} profile picture">
              <h5 class="card-title">{{ doc.firstName }} {{ doc.lastName }}</h5>
              <p class="card-title">{{ doc.title }}</p>
              <div class="view-profile-btn">
                <button class="btn btn-primary view-faculty" (click)="selectedCaptain = doc.id; selectedIdx = idx; scrollToTop()">View Profile</button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
</div>
